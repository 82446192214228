import React from 'react'
import { Link } from 'gatsby'
import './header.scss';

const Header = ({ siteTitle }) => (
  <div className="site-header">
    <div className="site-header__title-row">
      <h1 className="site-header__site-title">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div className="site-header__navigation-row">
      <Link to="/">Home</Link>
      <Link to="/prints">Prints</Link>
      {/* <Link to="/merch">Merch</Link> */}
      <Link to="/contact">Contact</Link>
    </div>
  </div>
)

export default Header
