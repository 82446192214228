import React from 'react'
import Layout from '../../components/layout';

export default () => {
  return (
    <Layout>
        <div className="page-layout prints-page">
            <div className="container">
                <h1>Contact</h1>
                <p>
                    To purchase a print - or to commission an original piece, 
                    please contact Madeline using the information below.
                    <br /><br />
                    All business transactions are done through paypal.
                </p>
                <p>
                    <b>email:</b> <a href="mailto:madelinesipp@gmail.com/">madelinesipp@gmail.com</a>
                    <br />
                    <b>instagram:</b> <a href="https://www.instagram.com/madelineesipp/">@madelineesipp</a>
                </p>
            </div>
        </div>
    </Layout>
  )
}
